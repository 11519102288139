import moment from 'moment';
import { Company } from 'uai-graos-sdk';
import { formatPhoneTable } from '../../../core/utils/formatPhoneNumber';
import * as S from './styles';
import { ExclamationCircleFilled } from '@ant-design/icons';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { Modal } from 'antd';

export default function KanBanCard(props: Company.Company) {
  const { confirm } = Modal;

  function callWhatsApp() {
    confirm({
      title: 'Atenção!',
      icon: <ExclamationCircleFilled />,
      content: `Deseja entrar em contato com ${props.name} via WhatsApp? ${formatPhoneTable(props.phone!)}`,
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => window.open(`https://wa.me/${props.phone}`, '_blank')
    });
  }

  return (
    <S.Container>
      <S.Name>{props.name}</S.Name>
      <S.LocationArea>
        <LocationCityIcon sx={{ marginRight: 1 }} />
        {props.city} - {props.state}
      </S.LocationArea>
      <div>
        Criado em:{' '}
        <b>
        {moment(props.createdAt).format('DD/MM/YYYY')}
        </b>
      </div>
      <S.PhoneButtonArea>
        <S.WhatsAppButton onClick={() => callWhatsApp()}>
          <LocalPhoneIcon sx={{ marginRight: 1 }} />
          {formatPhoneTable(props.phone!)}
        </S.WhatsAppButton>
      </S.PhoneButtonArea>
    </S.Container>
  );
}
