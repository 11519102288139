export default function getSideBarColor(pathname?: string) {
  if (window.location.pathname === pathname) {
    return {
      backgroundColor: '#1e8fff',
      color: '#fff',
    };
  }
  return {
    backgroundColor: '#fff',
    color: '#000',
  };
}
export function getMenuColor(colapsed?: boolean) {
  if (colapsed) {
    return {
      backgroundColor: '#1976d2',
      color: '#fff',
      
    };
  }
  return {
    backgroundColor: '#fff',
    color: '#000',
  };
}
