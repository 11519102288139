import moment from "moment";
import { formatPhoneTable } from "../../../core/utils/formatPhoneNumber";

export default function KanBanDialog(props: any): JSX.Element {
    return (
      <div className='card-template'>
        <div className='e-card-content'>
          <table className='card-template-wrap'>
            <tbody>
              <tr>
                <td className='CardHeader'>Nome:</td>
                <td>{props.name}</td>
              </tr>
              <tr>
                <td className='CardHeader'>Cidade:</td>
                <td>{props.city} - {props.state}</td>
              </tr>
              <tr>
                <td className='CardHeader'>Criado em:</td>
                <td> {moment(props.createdAt).format('DD/MM/YYYY')}</td>
              </tr>
              <tr>
                <td className='CardHeader'>Telefone:</td>
                <td>{formatPhoneTable(props.phone!)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }