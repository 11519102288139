import styled from "styled-components";

export const Container = styled.div`
    padding: 5px;
`

export const  Name = styled.div`
    font-size: 16px;
    font-weight: bold;
`

export const WhatsAppButton = styled.div`
    cursor: pointer;
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    background-color: #e8e8e8;
    border-radius: 5px;
`
export const LocationArea = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`
export const PhoneButtonArea = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`