import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from '@syncfusion/ej2-react-kanban';
import '../../index.less';
import { useCompany } from '../../core/hooks/useCompany';
import KanBanCard from '../components/KanBanCard';
import KanBanDialog from '../components/KanBanDialog';

export default function PreRegisterCRM() {
  const { accounts } = useCompany();
 
  return (
    <div>
      <KanbanComponent
        id='kanban'
        keyField='type'
        dataSource={accounts}
        cardSettings={{
          contentField: 'city',
          headerField: 'id',
          template: KanBanCard,
        }}
        dialogSettings={{
            model:{
                
                header: 'Visualizar pré-cadastro',
            },
            template: KanBanDialog,
        }}
        dragStop={(e) => console.log(e.data[0])}
      >
        <ColumnsDirective>
          <ColumnDirective
            headerText='Novo Contato'
            keyField='BUYER'
          />
          <ColumnDirective
            headerText='Contato não estabelecido'
            keyField='SELLER'
          />
          <ColumnDirective
            headerText='Segunda Tentativa'
            keyField='BROKER'
          />
          <ColumnDirective
            headerText='Visita Agendada'
            keyField='STORAGE'
          />
          <ColumnDirective
            headerText='Contato Feito'
            keyField='Contato Feito'
          />
          <ColumnDirective
            headerText='Sem Interesse'
            keyField='Sem Interesse'
          />
          <ColumnDirective
            headerText='Lead Desqualificada'
            keyField='Lead Desqualificada'
          />
        </ColumnsDirective>
      </KanbanComponent>
    </div>
  );
}
